<template>
  <div>
    <KTCodePreview title="Cấu hình giá theo chi nhánh">
      <template v-slot:preview>
        <b-container>
          <b-row>
            <b-col>
              <h4 style="color='rgb(24, 28, 50)'" class="mb-6">
                Thông tin cấu hình
              </h4>

              <b-form-group>
                <label class="d-block">Sản phẩm:</label>
                <b-form-input
                  size="sm"
                  disabled
                  v-model="productPriceInfoProductName"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="d-block">Chi nhánh:</label>
                <b-form-input
                  size="sm"
                  disabled
                  v-model="productPriceInfoStoreName"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="d-block">Trạng thái:</label>
                <b-form-select
                  v-model="productPriceInfoStatus"
                  :options="statusOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                v-if="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
              >
                <label class="d-block">Giá bán:</label>
                <b-form-input
                  size="sm"
                  v-model="productPriceInfoSellingPrice"
                  v-mask="mask"
                  class="text-right"
                ></b-form-input>
              </b-form-group>

              <div class="float-left">
                <b-button
                  style="fontweight: 600; width: 90px"
                  variant="primary"
                  size="sm"
                  @click="updateProductPrice"
                  >Xác nhận</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="cancelUpdate"
                  >Hủy</b-button
                >
              </div>
            </b-col>
            <b-col>
              <h4 style="color='rgb(24, 28, 50)'" class="mb-6">
                Lịch sử chỉnh sửa
              </h4>
              <b-table
                striped
                bordered
                hover
                :items="historyData"
                :fields="historyFields"
                :busy="isLoading"
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="20"
                  ></vcl-table>
                </template>
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>

                <template #cell(oldPrice)="row">
                  <b>
                    {{ formatPrice(row.item.oldPrice) }}
                  </b>
                </template>

                <template #cell(newPrice)="row">
                  <b>
                    {{ formatPrice(row.item.newPrice) }}
                  </b>
                </template>

                <template #cell(createdAt)="row">
                  {{ formatDate(row.item.createdAt) }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '../../../core/services/api.service';
import {
  formatDate,
  makeToastFaile,
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  delay,
  formatPrice
} from '../../../utils/common';
import { checkPermission } from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';

export default {
  data() {
    return {
      id: null,
      historyFields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '4%' },
          tdClass: 'text-center align-middle'
        },
        {
          key: 'action',
          label: 'Hành động',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-left align-middle'
        },
        {
          key: 'oldPrice',
          label: 'Giá cũ',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-right align-middle'
        },
        {
          key: 'newPrice',
          label: 'Giá mới',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-right align-middle'
        },
        {
          key: 'createdBy',
          label: 'Người cập nhật',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-left align-middle'
        },
        {
          key: 'createdAt',
          label: 'Thời gian',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-center align-middle'
        }
      ],
      statusOptions: [
        {
          value: 1,
          text: 'Hoạt động'
        },
        {
          value: 0,
          text: 'Khóa'
        }
      ],
      historyData: [],
      productPriceInfoProductName: '',
      productPriceInfoStoreName: '',
      productPriceInfoSellingPrice: 0,
      productPriceInfoStatus: null,
      mask: currencyMask,
      isLoading: false
    };
  },
  components: {
    KTCodePreview,
    VclTable
  },
  created() {
    this.id = this.$route.params.id;

    this.fetchDetail();
    this.fetchHistory();
  },
  computed: {},
  methods: {
    formatPrice,
    fetchDetail() {
      ApiService.get('/product_price/' + this.id).then(({ data }) => {
        const productPrice = data.data;
        this.productPriceInfoProductName = productPrice.productName;
        this.productPriceInfoStoreName = productPrice.storeName;
        this.productPriceInfoSellingPrice = productPrice.sellingPrice;
        this.productPriceInfoStatus = productPrice.active;
      });
    },
    fetchHistory() {
      this.isLoading = true;
      ApiService.get('/product_price/history/' + this.id)
        .then(({ data }) => {
          const histories = data.data;
          this.historyData = histories;

          this.isLoading = false;
        })
        .catch(() => {
          makeToastFaile('failed');
        });
    },
    formatDate,
    cancelUpdate() {
      this.$router.push({
        name: 'list-product-price'
      });
    },
    updateProductPrice() {
      ApiService.put(`product_price/${this.id}`, {
        sellingPrice: parseFloat(
          unMaskPrice(this.productPriceInfoSellingPrice)
        ),
        active: this.productPriceInfoStatus
      })
        .then(async () => {
          makeToastSuccess('Cập nhật thành công');
          await delay();
          this.fetchHistory();
          this.fetchDetail();
        })
        .catch(err => {
          makeToastFaile('Cập nhật thất bại. Lý do: ', err);
        });
    },
    checkPermission,
  }
};
</script>
